<template>
  <section id="slider">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>Slider</h1>
        <h5 class="text-primary">
          {{ $t("slider.subtitle") }}
        </h5>
      </b-col>
    </b-row>
    <b-row v-if="completo">
      <b-col>
        <b-tabs
          v-if="!isEmpty(slider)"
          id="tabs"
          v-model="tabIndex"
          pills
          card
          lazy
        >
          <!-- SLIDER -->

          <b-tab
            v-for="element in slider"
            :id="isEmpty(element) ? 'nuevo' : 'dyn-tab-' + element.node.id"
            :key="isEmpty(element) ? 'nuevo' : 'dyn-tab-' + element.node.id"
            class="tab"
            :disabled="!isEmpty(element) ? disabledTab : false"
            :active="isEmpty(element)"
            :title="!isEmpty(element) ? (isEmpty(element.node.name) ? typeSl(element.node.slideType) : element.node.name) : $t('newSlide')"
            @click="getSlides(isEmpty(element) ? null : element.node.id)"
          >
            <b-row v-if="!isEmpty(element)">
              <b-col>
                <b-card>
                  <b-card-header>
                    <b-card-title class="d-flex">
                      <b-form
                        class="d-flex align-items-center"
                        @submit.prevent="changeName(element.node)"
                      >
                        <div>
                          <h2 v-if="!editName">
                            {{ isEmpty(element.node.name) ? typeSl(element.node.slideType) :
                              element.node.name }}
                          </h2>
                          <b-form-input
                            v-else
                            v-model="element.node.name"
                            required
                            :placeholder="$t('firstName')"
                          />
                          <h4 class="text-primary d-flex mt-1">
                            <b-form-checkbox
                              v-if="editName"
                              v-model="element.node.isRoot"
                              switch
                            />{{
                              element.node.isRoot ? 'Root' :
                              $t('purchases.category') }}
                          </h4>
                          <h5 class="text-muted mt-25">
                            {{ !isEmpty(element.node.name) ? typeSl(element.node.slideType) :
                              $t('slider.sliderSlide') }}
                          </h5>
                        </div>
                        <div
                          v-if="isEmpty(slides)"
                          class="ml-2"
                        >
                          <span
                            class="cursor-pointer text-danger"
                            @click.stop.prevent="deleteSlider($event, element.node.id)"
                          >
                            <feather-icon
                              icon="Trash2Icon"
                              size="18"
                            />
                          </span>
                        </div>
                        <div
                          v-if="!editName"
                          class="ml-2"
                        >
                          <span
                            class="cursor-pointer text-success"
                            @click.stop.prevent="editName = !editName"
                          >
                            <feather-icon
                              icon="EditIcon"
                              size="18"
                            />
                          </span>
                        </div>
                        <div v-else>
                          <b-button
                            type="submit"
                            class="ml-2"
                            variant="success"
                          >
                            {{ $t('dataGeneric.save') }}
                          </b-button>
                        </div>
                      </b-form>
                    </b-card-title>

                    <div class="d-flex align-items-baseline">
                      <b-form-checkbox
                        v-model="element.node.isActive"
                        class="mr-2 align-self-center"
                        style="padding-top: 10px;"
                        switch
                        @change="changeStatus(element.node)"
                      >
                        <b-badge
                          :class="element.node.isActive
                            ? 'badge-light-success cursor-pointer'
                            : 'badge-light-danger cursor-pointer'
                          "
                        >
                          <h4 :class="element.node.isActive ? 'text-success' : 'text-danger'">
                            {{ element.node.isActive ? $t('dataGeneric.active') : $t('slider.deactiveSlider') }}
                          </h4>
                        </b-badge>
                      </b-form-checkbox>

                      <b-dropdown
                        right
                        class="mr-1"
                        :text="$t('contents.sort')"
                      >
                        <b-dropdown-item @click="manual = !manual">
                          {{ $t('contents.manually') }}
                        </b-dropdown-item>
                      </b-dropdown>

                      <b-button
                        variant="primary"
                        class="ls-1 text-white mb-1 mt-1"
                        @click="AddSlide(element.node)"
                      >
                        <span>{{ $t("slider.create") }}</span>
                      </b-button>
                    </div>
                  </b-card-header>
                  <div
                    v-if="manual"
                    class="mb-2 mt-1 ml-1 d-flex align-items-center"
                  >
                    <b-button
                      variant="outline-warning"
                      disabled
                    >
                      {{ $t("contents.manualSortMode") }}
                    </b-button>
                    <b-button
                      class="ml-2"
                      variant="success"
                      @click="manual = !manual"
                    >
                      {{ $t('contents.finalize') }}
                    </b-button>
                  </div>
                  <div
                    v-if="!manual"
                    class="mb-2 mt-1 d-flex justify-content-end align-items-center"
                  >
                    <b-form-checkbox
                      v-model="select"
                      class="mr-2"
                      switch
                    >
                      {{ $t('slider.selectionMultiple')
                      }}
                    </b-form-checkbox>
                    <b-form-checkbox
                      v-model="all"
                      class="mr-2"
                      switch
                    >
                      {{ $t('slider.allSelect') }}
                    </b-form-checkbox>
                    <div class="mr-2 ml-1">
                      <b-form-select
                        v-model="stateFilter"
                        :options="optionsStatus"
                        @change="getSlides(isEmpty(element) ? null : element.node.id)"
                      />
                    </div>
                    <div>
                      <b-dropdown
                        :disabled="!select"
                        variant="primary"
                        class="mr-1"
                        :text="$t('resources.tableHeader.actions')"
                      >
                        <b-dropdown-item
                          :disabled="selectSlide.length == 0 ? true : false"
                          @click="statusSlide(true)"
                        >
                          {{ $t('activar') }}<span class="ml-50 bullet bullet-success bullet-sm" />
                        </b-dropdown-item>
                        <b-dropdown-item
                          :disabled="selectSlide.length == 0 ? true : false"
                          @click="statusSlide(false)"
                        >
                          {{ $t('slider.deactivate') }}<span class="ml-50 bullet bullet-danger bullet-sm" />
                        </b-dropdown-item>
                        <b-dropdown-item
                          :disabled="selectSlide.length == 0 ? true : false"
                          variant="danger"
                          @click="deleteSlide($event)"
                        >
                          {{ $t('dataGeneric.delete') }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                  <!-- SLIDES -->
                  <b-overlay
                    v-if="!isEmpty(slides)"
                    variant="transparent"
                    :show="overlay"
                  >
                    <b-list-group>
                      <!-- ORDER MANUAL -->
                      <draggable
                        v-if="manual"
                        v-model="slides"
                        class="list-group list-group-flush cursor-move"
                        tag="ul"
                      >
                        <div
                          v-for="combined in slides"
                          :key="combined.normalSlide + '' + combined.order"
                          @dragend="updateOrder(false)"
                        >
                          <b-card border-variant="primary">
                            <b-card-header>
                              <b-col>
                                <b-card-title>
                                  {{ 'Posición ' + (parseInt(combined.order, 10) + 1) }}
                                </b-card-title>
                                <small
                                  v-if="!combined.normalSlide"
                                  class="text-muted "
                                >
                                  ({{ $t('Publicidad') }})
                                </small>
                              </b-col>
                              <b-col class="d-flex justify-content-end align-items-center">
                                <div>
                                  <span class="cursor-move text-warning">
                                    <feather-icon
                                      icon="MenuIcon"
                                      size="21"
                                    />
                                  </span>
                                </div>
                              </b-col>
                            </b-card-header>
                            <!-- NORMAL SLIDES -->
                            <b-list-group-item
                              v-if="combined.normalSlide"
                              tag="b-row"
                              class="d-flex"
                            >
                              <b-col md="3">
                                <div class="m-1 align-items-center">
                                  <h3
                                    :class="combined.slides.isActive ? 'text-success' : 'text-danger'
                                    "
                                  >
                                    <b-form-checkbox
                                      v-if="select"
                                      disabled
                                      :value="combined.slides.id"
                                      class="mr-2"
                                    />
                                    <feather-icon
                                      v-if="combined.slides.isActive"
                                      v-b-tooltip.hover.top="released(combined.slides)
                                        ? $t('slideVis')
                                        : $t('slideOc')
                                      "
                                      class="text-white"
                                      :icon="released(combined.slides) ? 'EyeIcon' : 'EyeOffIcon'"
                                    />
                                    {{ combined.slides.name }}
                                  </h3>
                                  <strong>Slide {{ type[combined.slides.slideType] }}</strong>
                                </div>
                              </b-col>
                              <b-col md="4">
                                <b-aspect :aspect="aspect">
                                  <b-img
                                    fluid
                                    :src="buildImageUrl(combined.slides)"
                                  />
                                </b-aspect>
                              </b-col>
                            </b-list-group-item>
                            <!-- ADSLIDES -->
                            <b-list-group v-else>
                              <b-list-group-item
                                v-for="adSlide in combined.adSlides"
                                :key="adSlide.id"
                                tag="b-row"
                                vertical
                                class="d-flex"
                              >
                                <b-col md="3">
                                  <div class="m-1 align-items-center">
                                    <h3
                                      :class="adSlide.isActive ? 'text-success' : 'text-danger'
                                      "
                                    >
                                      <feather-icon
                                        v-if="adSlide.isActive"
                                        v-b-tooltip.hover.top="released(adSlide)
                                          ? $t('slideVis')
                                          : $t('slideOc')
                                        "
                                        class="text-white"
                                        :icon="released(adSlide) ? 'EyeIcon' : 'EyeOffIcon'"
                                      />
                                      {{ adSlide.name }}
                                    </h3>
                                    <strong>Slide {{ type[adSlide.slideType] }}</strong>
                                  </div>
                                </b-col>
                                <b-col md="4">
                                  <b-aspect :aspect="aspect">
                                    <b-img
                                      fluid
                                      :src="buildImageUrl(adSlide)"
                                    />
                                  </b-aspect>
                                </b-col>
                              </b-list-group-item>
                            </b-list-group>
                          </b-card>
                        </div>
                      </draggable>
                      <!-- ELEMENTS SLIDES -->
                      <b-form-checkbox-group
                        v-else
                        id="checkbox-group-1"
                        v-model="selectSlide"
                        name="some-radios"
                      >
                        <div
                          v-for="combined in slides"
                          :key="combined.normalSlide + '' + combined.order"
                        >
                          <b-card border-variant="primary">
                            <b-card-header>
                              <b-col>
                                <b-card-title class="d-flex align-items-center">
                                  {{ $t('position') + ' ' + (parseInt(combined.order, 10) + 1) }}
                                </b-card-title>
                                <small
                                  v-if="!combined.normalSlide"
                                  class="text-muted "
                                >
                                  ({{ $t('Publicidad') }})
                                </small>
                              </b-col>
                              <b-col
                                v-if="!combined.normalSlide"
                                class="d-flex justify-content-end align-items-center"
                              >
                                <div>
                                  <b-button
                                    variant="primary"
                                    size="sm"
                                    @click="AddSlide(element.node, combined.order)"
                                  >
                                    <span class="d-flex align-items-center">
                                      <feather-icon
                                        icon="PlusCircleIcon"
                                        size="21"
                                      />
                                      <div class="ml-1">
                                        {{ $t('Add') }}
                                      </div>
                                    </span>
                                  </b-button>
                                </div>
                              </b-col>
                            </b-card-header>
                            <b-list-group-item
                              v-if="combined.normalSlide"
                              :active="selectSlide.includes(combined.slides.id) ? true : false"
                              tag="b-row"
                              class="d-flex"
                              @click="
                                !select ? editSlider($event, combined.slides) : clickCheck(combined.slides)
                              "
                            >
                              <b-col md="3">
                                <div class="m-1 align-items-center">
                                  <h3
                                    :class="combined.slides.isActive ? 'text-success' : 'text-danger'
                                    "
                                  >
                                    <b-form-checkbox
                                      v-if="select"
                                      disabled
                                      :value="combined.slides.id"
                                      class="mr-2"
                                    />

                                    <feather-icon
                                      v-if="combined.slides.isActive"
                                      v-b-tooltip.hover.top="released(combined.slides)
                                        ? $t('slideVis')
                                        : $t('slideOc')
                                      "
                                      class="text-white"
                                      :icon="released(combined.slides) ? 'EyeIcon' : 'EyeOffIcon'"
                                    />
                                    {{ combined.slides.name }}
                                  </h3>
                                  <strong>Slide {{ type[combined.slides.slideType] }}</strong>
                                </div>
                              </b-col>
                              <b-col md="4">
                                <b-aspect :aspect="aspect">
                                  <b-img
                                    fluid
                                    :src="buildImageUrl(combined.slides)"
                                  />
                                </b-aspect>
                              </b-col>
                              <b-col class="d-flex justify-content-end align-items-center">
                                <div class="mr-1">
                                  <span
                                    class="cursor-pointer text-success"
                                    @click.stop.prevent="editSlider($event, combined.slides)"
                                  >
                                    <feather-icon
                                      icon="EditIcon"
                                      size="21"
                                    />
                                  </span>
                                </div>
                                <div>
                                  <span
                                    class="cursor-pointer text-danger"
                                    @click.stop.prevent="deleteSlide($event, combined.slides.id)"
                                  >
                                    <feather-icon
                                      icon="Trash2Icon"
                                      size="21"
                                    />
                                  </span>
                                </div>
                              </b-col>
                            </b-list-group-item>
                            <b-list-group v-else>
                              <b-list-group-item
                                v-for="adSlide in combined.adSlides"
                                :key="adSlide.id"
                                :active="selectSlide.includes(adSlide.id) ? true : false"
                                tag="b-row"
                                vertical
                                class="d-flex"
                                @click="
                                  !select ? editSlider($event, adSlide) : clickCheck(adSlide)
                                "
                              >
                                <b-col md="3">
                                  <div class="m-1 align-items-center">
                                    <h3
                                      :class="adSlide.isActive ? 'text-success' : 'text-danger'
                                      "
                                    >
                                      <b-form-checkbox
                                        v-if="select"
                                        disabled
                                        :value="adSlide.id"
                                        class="mr-2"
                                      />

                                      <feather-icon
                                        v-if="adSlide.isActive"
                                        v-b-tooltip.hover.top="released(adSlide)
                                          ? $t('slideVis')
                                          : $t('slideOc')
                                        "
                                        class="text-white"
                                        :icon="released(adSlide) ? 'EyeIcon' : 'EyeOffIcon'"
                                      />
                                      {{ adSlide.name }}
                                    </h3>
                                    <strong>Slide {{ type[adSlide.slideType] }}</strong>
                                  </div>
                                </b-col>
                                <b-col md="4">
                                  <b-aspect :aspect="aspect">
                                    <b-img
                                      fluid
                                      :src="buildImageUrl(adSlide)"
                                    />
                                  </b-aspect>
                                </b-col>
                                <b-col class="d-flex justify-content-end align-items-center">
                                  <div class="mr-1">
                                    <span
                                      class="cursor-pointer text-success"
                                      @click.stop.prevent="editSlider($event, adSlide)"
                                    >
                                      <feather-icon
                                        icon="EditIcon"
                                        size="21"
                                      />
                                    </span>
                                  </div>
                                  <div>
                                    <span
                                      class="cursor-pointer text-danger"
                                      @click.stop.prevent="deleteSlide($event, adSlide.id)"
                                    >
                                      <feather-icon
                                        icon="Trash2Icon"
                                        size="21"
                                      />
                                    </span>
                                  </div>
                                </b-col>
                              </b-list-group-item>
                            </b-list-group>
                          </b-card>
                        </div>
                      </b-form-checkbox-group>
                    </b-list-group>
                  </b-overlay>
                  <b-jumbotron
                    v-else
                    bg-variant="transparent"
                    border-variant="primary"
                    :header="$t('AddSLide')"
                    class="text-center"
                  >
                    <p class="text-primary">
                      {{ $t('firstSlide') }}
                    </p>
                    <b-col
                      class="text-center m-10 p-5"
                      align-self="center"
                    >
                      <b-button
                        variant="primary"
                        class="ls-1 text-white mb-1 mt-1"
                        @click="AddSlide(element.node)"
                      >
                        <span>{{ $t("slider.create") }}</span>
                      </b-button>
                    </b-col>
                  </b-jumbotron>
                </b-card>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col v-if="!overlay">
                <b-card>
                  <b-card-header class="d-block">
                    <b-card-title>
                      <h2>
                        {{ $t('slider.createSlider') }}
                      </h2>
                    </b-card-title>
                    <b-card-text class="text-muted mt-25">
                      {{ $t('slider.conf') }}
                    </b-card-text>
                  </b-card-header>
                  <b-card-body>
                    <b-form @submit.prevent="createSlider">
                      <b-row>
                        <b-col>
                          <b-form-group
                            :label="$t('firstName')"
                            class="text-justify"
                          >
                            <div>
                              <b-form-input
                                id="name"
                                v-model="name"
                                required
                                autocomplete="new-password"
                                maxlength="130"
                                :placeholder="$t('firstName')"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row class="mt-2 mb-2 ">
                        <b-col md="3">
                          <b-form-group :label="$t('slider.root')">
                            <div>
                              <b-form-checkbox
                                v-model="isRoot"
                                switch
                              >
                                Root
                              </b-form-checkbox>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col>
                          <b-tabs
                            v-if="isRoot && isShop"
                            v-model="tabType"
                            pills
                            class="mt-2 d-flex justify-content-center"
                          >
                            <b-tab active>
                              <template #title>
                                <feather-icon icon="VideoIcon" />
                                <span>{{ $t("Contents") }}</span>
                              </template>
                            </b-tab>
                            <b-tab>
                              <template #title>
                                <feather-icon icon="ShoppingCartIcon" />
                                <span>{{ $t("Shop") }}</span>
                              </template>
                            </b-tab>
                          </b-tabs>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-button
                            variant="success"
                            type="submit"
                          >
                            <span>{{ $t('slider.createSlider') }}</span>
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </b-tab>

          <template #tabs-end>
            <b-nav-item
              v-if="!isEmpty(slider[tabIndex])"
              role="presentation"
              href="#"
              @click.prevent="newTab"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="text-success"
                size="20"
              />
            </b-nav-item>
            <b-nav-item
              v-else
              href="#"
              @click.prevent="cleanTab"
            >
              <feather-icon
                icon="TrashIcon"
                class="text-danger"
                size="20"
              />
            </b-nav-item>
          </template>
        </b-tabs>
        <b-card v-else>
          <b-card-header>
            <b-card-title>
              <h1>
                {{ $t("slider.create2") }}
              </h1>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <b-form @submit.prevent="createSlider">
              <b-row>
                <b-col>
                  <b-form-group
                    :label="$t('firstName')"
                    class="text-justify"
                  >
                    <div>
                      <b-form-input
                        id="name"
                        v-model="name"
                        required
                        autocomplete="new-password"
                        maxlength="130"
                        :placeholder="$t('firstName')"
                      />
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-2 mb-2 ">
                <b-col md="3">
                  <b-form-group :label="$t('slider.root')">
                    <div>
                      <b-form-checkbox
                        v-model="isRoot"
                        switch
                      >
                        Root
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-tabs
                    v-if="isRoot && isShop"
                    v-model="tabType"
                    pills
                    class="mt-2 d-flex justify-content-center"
                  >
                    <b-tab active>
                      <template #title>
                        <feather-icon icon="VideoIcon" />
                        <span>{{ $t("Contents") }}</span>
                      </template>
                    </b-tab>
                    <b-tab>
                      <template #title>
                        <feather-icon icon="ShoppingCartIcon" />
                        <span>{{ $t("Shop") }}</span>
                      </template>
                    </b-tab>
                  </b-tabs>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    variant="success"
                    type="submit"
                  >
                    <span>{{ $t('slider.createSlider') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BTab,
  BTabs,
  BForm,
  BRow,
  BCol,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
  BButton,
  BFormGroup,
  BCard,
  BImg,
  BCardHeader,
  BCardBody,
  BBadge,
  BDropdown,
  BFormSelect,
  BDropdownItem,
  BListGroupItem,
  BListGroup, VBTooltip,
  BJumbotron,
  BOverlay,
  BCardTitle,
  BCardText,
  BAspect,
  BNavItem,
} from 'bootstrap-vue'
import axios from '@axios'
import { messageError, showToast, isEmpty } from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { getUserData } from '@/auth/utils'

const noCover = require('@/assets/images/backend/nocover.jpeg')
const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BTab,
    BTabs,
    BFormSelect,
    BCardHeader,
    BJumbotron,
    BCardBody,
    BAspect,
    BCardTitle,
    BCardText,
    BListGroup,
    BOverlay,
    BBadge,
    BFormCheckboxGroup,
    draggable,
    BNavItem,
    BDropdown,
    BDropdownItem,
    BListGroupItem,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BButton,
    BCard,
    BImg,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isShop: getUserData().profile.client.isShop,
      userData: getUserData(),
      slider: [],
      isEmpty,
      name: null,
      vacio: true,
      nameSlider: null,
      aspect: 1920 / 575,

      overlay: false,
      completo: false,
      manual: false,
      select: false,
      tabIndex: 0,
      tabType: 0,
      all: false,
      selectSlide: [],
      sliderCont: [],
      editName: false,
      index: 0,
      isRoot: false,
      headers: {},
      stateFilter: null,
      optionsStatus: [
        { value: null, text: this.$t('subscriptions.status') },
        { value: true, text: `🟢 ${this.$t('ecommerce.public')}` },
        { value: false, text: `🔴 ${this.$t('ecommerce.NoPublic')}` },
      ],
      type: {
        SHO: this.$t('Shop'),
        CON: this.$t('Contents'),
        URL: 'URL',
        CAT: this.$t('purchases.category'),
        CAP: this.$t('slider.productCategory'),
        PRO: this.$t('producto'),
      },
      disabledTab: false,
      slides: [],
    }
  },
  watch: {

    isRoot() {
      this.tabType = 0
    },
    tabIndex() {
      this.select = false
    },
    all() {
      const allSlidesCombined = [
        ...this.slides
          .filter(item => item.slides) // Filtra solo los objetos que tienen slides
          .map(item => item.slides), // Extrae los slides en un array
        ...this.slides
          .filter(item => item.adSlides) // Filtra solo los objetos que tienen adSlides
          .flatMap(item => item.adSlides), // Aplana los arrays de adSlides en un solo array
      ]
      if (this.all) {
        this.select = this.all
        this.selectSlide = []
        allSlidesCombined.forEach(element => {
          this.selectSlide.push(element.id)
        })
      } else if (this.selectSlide.length === allSlidesCombined.length) {
        this.selectSlide = []
      }
    },
    select() {
      if (!this.select) {
        this.selectSlide = []
        this.all = this.select
      }
    },
    selectSlide() {
      const allSlidesCombined = [
        ...this.slides
          .filter(item => item.slides) // Filtra solo los objetos que tienen slides
          .map(item => item.slides), // Extrae los slides en un array
        ...this.slides
          .filter(item => item.adSlides) // Filtra solo los objetos que tienen adSlides
          .flatMap(item => item.adSlides), // Aplana los arrays de adSlides en un solo array
      ]
      if (this.selectSlide.length !== allSlidesCombined.length) {
        this.all = false
      }
    },
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {

    }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.getSliderTabs()
  },
  methods: {
    changeName(node) {
      const { id } = node
      const { name } = node
      const root = node.isRoot
      axios
        .post('', {
          query: `
         mutation{
           updateSlider(id:"${id}",input:{name:"${name}", isRoot:${root}}){
            slider{
              id
            }
          }
        }
        `,
        })
        .then(res => {
          messageError(res, this)

          this.editName = !this.editName
          showToast(this.$t('success'), 1, this)
        })
        .catch(res => {
          console.log(res)
          showToast(this.$t('error'), 0, this)
        })
    },
    changeStatus(node) {
      const { id } = node
      const active = node.isActive

      axios
        .post('', {
          query: `
         mutation{
           updateSlider(id:"${id}",input:{isActive:${active}}){
            slider{
              id
            }
          }
        }
        `,
        })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('success'), 1, this)
        })
        .catch(res => {
          console.log(res)

          showToast(this.$t('error'), 0, this)
        })
    },
    createSlider() {
      const type = this.tabType === 0 ? 'CON' : 'SHO'
      axios
        .post('', {
          query: `
         mutation{
          createSlider(input:{client:"${this.userData.profile.client.id}",slideType:${type},
          name:"${this.name}",isActive:true, isRoot:${this.isRoot}}){
            slider{
              id
            }
          }
        }
        `,
        })
        .then(res => {
          messageError(res, this)
          this.disabledTab = false
          this.name = null
          this.isRoot = false
          showToast(this.$t('slider.exito'), 1, this)
          this.getSliderTabs()
        })
        .catch(res => {
          console.log(res)

          showToast(this.$t('slider.error'), 0, this)
        })
    },
    clickCheck(node) {
      const i = this.selectSlide.indexOf(node.id)

      if (i !== -1) {
        this.selectSlide.splice(i, 1)
      } else {
        this.selectSlide.push(node.id)
      }
    },
    typeSl(type) {
      let string = ''
      if (type === 'CON') {
        string = this.$t('Slider de contenidos')
      } else if (type === 'SHO') {
        string = this.$t('Slider de tienda')
      } else {
        string = this.$t('newSlide')
      }
      return string
    },
    cleanTab() {
      this.disabledTab = false

      this.slider.pop()
      this.tabIndex = this.slider.length - 1
      this.getSliderTabs()
    },
    newTab() {
      this.disabledTab = true
      this.slider.push([])
    },
    getSliderTabs(update = false) {
      const { headers } = this
      this.overlay = true

      axios
        .post('', {
          query: `
            query{
              slider(client:"${this.userData.profile.client.id}",orderBy:"-isRoot,createdAt"){
                totalCount
                edges {
                  node {
                    id
                    name
                    slideType
                    isActive
                    isRoot                    
                  }
                }
              }
            }
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)

          this.completo = true
          const slider = res.data.data.slider.edges
          this.slider = slider
          if (!isEmpty(this.slider)) {
            if (this.slider.length === this.tabIndex) {
              this.getSlides(this.slider[this.tabIndex - 1].node.id, update)
            } else {
              this.getSlides(this.slider[this.tabIndex].node.id, update)
            }
          }
          this.vacio = res.data.data.slider.totalCount === 0
          this.overlay = false
        })
    },
    getSlides(idSlider, update = false) {
      const { headers } = this
      this.slides = []
      this.overlay = true
      if (!isEmpty(idSlider)) {
        axios
          .post('', {
            query: `
          query{
            slider(id:"${idSlider}",client:"${this.userData.profile.client.id}",orderBy:"-isRoot,createdAt"){
             totalCount
              edges {
                node {
                  id
                  name
                  slideType
                  isActive
                  isRoot
                  combinedSlides${!isEmpty(this.stateFilter) ? `(isActive : ${this.stateFilter})` : ''}{
                    normalSlide
                    order
                    slides {
                      id
                      isActive
                      publishDate
                      expirationDate
                      slideType
                      name
                      order
                      imageUrl
                    }
                    adSlides {
                      id
                      isActive
                      publishDate
                      expirationDate
                      slideType
                      name
                      order
                      imageUrl
                    }
                  }
                  
                }
              }
            }
          }      
        `,
          }, { headers })
          .then(res => {
            messageError(res, this)

            this.completo = true
            const slider = res.data.data.slider.edges
            this.slides = slider[0].node.combinedSlides
            if (update) this.updateOrder(update)

            this.overlay = false
          })
      }
    },
    buildImageUrl(folder) {
      return folder.imageUrl == null || folder.imageUrl.length === 0
        ? noCover
        : folder.imageUrl
    },
    errorImg(e) {
      e.target.src = fileError
    },
    AddSlide(node, pos = null) {
      const { id } = node
      const type = node.slideType
      const position = pos == null ? this.slides.length : pos

      if (pos !== null) {
        this.$router.push({
          name: 'slide-create-sponsor',
          params: { idSlider: id, type, position },
        })
      } else {
        this.$router.push({
          name: 'slide-create',
          params: { idSlider: id, type, position },
        })
      }
    },
    editSlider(event, node) {
      event.preventDefault()

      const { id } = node
      let type = null
      try {
        type = this.slider[this.tabIndex].node.slideType
      } catch (error) {
        console.log(error)
      }
      if (!this.click) {
        this.$router.push({
          name: 'slide-edit',
          params: { id, type },
        })
      }
    },
    statusSlide(boolean) {
      let query = 'mutation{'
      this.selectSlide.forEach((element, i) => {
        query += `u${i + 1}: updateSlide(id:"${element}",input:{
              isActive: ${boolean},
              }){
              slide{
                name
                id
              }
            }`
      })

      query += ' }'
      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          this.getSliderTabs()

          showToast(this.$t('success'), 1, this)
        })
        .catch(error => {
          console.log(error)
          showToast(this.$t('error'), 0, this)
        })
    },
    updateOrder(notShow = false) {
      let query = ` mutation
      {`
      let orderBool = false
      this.slides.forEach((combined, i) => {
        this.slides[i].order = i
        if (combined.normalSlide) {
          const { id } = combined.slides
          query += `
        slide_${i + 1}: updateSlide(id:"${id}",input:{ order: ${i}}){
                    slide{
                      id
                      name
                      order
                    }
                  }
                `
        } else {
          combined.adSlides.forEach((element, n) => {
            const { id } = element
            query += `
            adSlide_${n + 1}_${i + 1}: updateAdSlide(id:"${id}",input:{ order: ${i},position:${i}}){
                    adSlide{
                      id
                      name
                      order
                    }
                  }
                `
          })
        }
        orderBool = true
      })

      query += `
      }`
      if (orderBool) {
        axios
          .post('', {
            query,
          })
          .then(res => {
            messageError(res, this)
            if (!notShow) showToast(this.$t('success'), 1, this)
          })
          .catch(error => {
            console.log(error)
            showToast(this.$t('error'), 0, this)
          })
      }
    },
    released(data) {
      const inicial = data.publishDate == null ? null : new Date(data.publishDate)
      const final = data.expirationDate == null ? null : new Date(data.expirationDate)
      const DateToCheck = new Date()

      if (inicial == null || inicial < DateToCheck) {
        if (final == null || final > DateToCheck) {
          return true
        }
        return false
      }
      return false
    },
    deleteSlide(event, id = null) {
      event.preventDefault()
      this.click = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          this.click = false

          if (result.value) {
            this.overlay = true
            if (id != null) {
              axios
                .post('', {
                  query: `
                mutation{
                  deleteSlide(id:"${id}"){
                    found
                    deletedId
                  }
                }
                `,
                })
                .then(res => {
                  messageError(res, this)

                  showToast(this.$t('slider.exDele'), 1, this)
                  this.overlay = false

                  this.getSliderTabs(true)
                })
                .catch(err => {
                  console.log(err)
                  this.overlay = false

                  showToast(this.$t('slider.erDele'), 0, this)
                })
            } else {
              let query = ' mutation{'
              let deleteBool = false
              this.selectSlide.forEach((element, i) => {
                query += `
                 d${i + 1}: deleteSlide(id:"${element}"){
                    found
                    deletedId
                  }`
                deleteBool = true
              })
              query += '}'
              if (deleteBool) {
                axios
                  .post('', {
                    query,
                  })
                  .then(r => {
                    messageError(r, this)

                    showToast(this.$t('slider.exDele'), 1, this)
                    this.overlay = false

                    this.getSliderTabs(true)
                  })
                  .catch(err => {
                    console.log(err)
                    this.overlay = false

                    showToast(this.$t('slider.erDele'), 0, this)
                  })
              }
            }
          }
        })
        .catch(err => {
          this.click = false

          console.log(err)
        })
    },
    deleteSlider(event, id) {
      event.preventDefault()
      this.click = true
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.overlay = true
            axios
              .post('', {
                query: `
                mutation{
                  deleteSlider(id:"${id}"){
                    found
                    deletedId
                  }
                }
                `,
              })
              .then(res => {
                messageError(res, this)

                showToast(this.$t('slider.exDele'), 1, this)
                this.overlay = false
                this.getSliderTabs()
              })
              .catch(err => {
                console.log(err)
                this.overlay = false
                showToast(this.$t('slider.erDele'), 0, this)
              })
          }
        })
        .catch(err => {
          this.click = false

          console.log(err)
        })
    },
  },
}
</script>
<style lang="scss" scoped>
#slider .custom-height {
  height: 330px;
}

#slider .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#slider .card-body h4 {
  font-size: 1.286rem !important;
}

#slider .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
  background: 0;
}

#slider .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#slider .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer !important;
}
</style>
